import classNames from "classnames";
import React, { FC, memo, ReactNode, useEffect } from "react";
import "./index.scss";

export interface RowProps {
  label: string | ReactNode;
  labelColor?: string;
  value: string | number | ReactNode;
  valueColor?: string;
  hasBorderBottom?: boolean;
  hasBorderBottom1?: boolean;
  action?: any;
  isHidden?: boolean;
  displayBlock?: boolean;
  labelWidth?: string;
  valueWidth?: string;
  isCheckbox?: boolean;
}

const AppRow: FC<RowProps> = memo(({ ...props }) => {
  const {
    label,
    labelColor,
    value,
    valueColor,
    hasBorderBottom,
    action,
    displayBlock,
    labelWidth,
    valueWidth,
    isCheckbox,
  } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!props.isHidden && (
        <div
          className={classNames("ui-row", { 'row-checkbox': isCheckbox })}
          style={{
            borderBottom: `solid ${hasBorderBottom ? "0.5px" : "0"} #D9DBE9`,
            display: props.isHidden ? "none" : displayBlock ? "block" : "flex",
          }}
        >
          <div
            className="ui-row__label"
            style={{
              color: labelColor || "#666666",
              paddingLeft: "5px",
              background: "#E8E8E8",
              wordBreak:'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              flexBasis: labelWidth || "50%",
              minWidth: labelWidth || "100px",
            }}
          >
            {label}
          </div>
          <div
            className="ui-row__value"
            style={{
              color: valueColor || "#333333",
              textDecoration: action ? "underline" : "none",
              cursor: action && "pointer",
              paddingLeft: "5px",
            }}
            onClick={action}
          >
            {value}
          </div>
        </div>
      )}
    </>
  );
});

export default AppRow;

AppRow.defaultProps = {};
